import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import laura1 from "../assets/465260850_941072904532200_7368489590219515522_n.jpg";
import laura2 from "../assets/465750427_1651042465625711_6980615251793590398_n.jpg";
import laura3 from "../assets/411434107_18314331001136736_1504431760301466684_n.jpg";
import laura4 from "../assets/314397904_128060410045096_2173803646322735508_n.jpg";
import lauraImg from "../assets/IMG_2839-1024x828.jpg";

import styles from "./Gianni.module.scss";
import { useNavigate, useParams } from "react-router-dom";

import {
  faFacebook,
  faInstagram,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { setCurrentMenu } from "../store/GianniTattooStore";

import { Button } from "@mui/material";
import { Google as GoogleIcon } from "@mui/icons-material";

const Laura: React.FC = () => {
  const redirect = useNavigate();
  const params = useParams();
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setCurrentMenu("laura");
  }, []);

  const handleButtonClick = () => {
    window.open(
      "https://search.google.com/local/writereview?placeid=ChIJZ1GObdzRfkcRQW7XzTszyoM",
      "_blank"
    );
  };

  const handleInstagram = () => {
    window.open("https://www.instagram.com/lauratruccoperfetto", "_blank");
  };

  const handleFacebook = () => {
    window.open("https://www.facebook.com/LauraTruccoPerfetto", "_blank");
  };

  const handleTikTok = () => {
    window.open("https://www.tiktok.com/@laura_cavinato", "_blank");
  };

  return (
    <>
      {/* <Header menu={() => setMenu(!menu)} /> */}

      <div className="d-flex flex-column align-items-center justify-content-center col-12 col-xl-5    p-5">
        {/* <div className="d-flex flex-row align-items-center justify-content-center col-12">
          <span
            className="color-black"
            style={{ fontSize: "3.8rem", fontWeight: "bold" }}
          >
            Laura
          </span>
        </div> */}
        <div
          className={`d-flex flex-row align-items-center justify-content-center col-12 ${styles.ProfileImgContainer} mt-5`}
        >
          <img src={lauraImg} />
        </div>

        <div className="d-flex flex-row align-items-center justify-content-center mt-7 col-xl-10 text-xxl">
          <span
            className="color-black text-center"
            // style={{ fontSize: "2rem" }}
          >
            <i>
              Il trucco permanente è un modo per valorizzare al meglio se
              stessi, esaltando le qualità di ognuno di noi, senza sconvolgerne
              l’essenza.
            </i>
          </span>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-center mt-5 col-xl-9 ">
          <span
            className="color-black text-center text-xxl "
            // style={{ fontSize: "2rem" }}
          >
            Sono estetista da oltre venti anni e mi sono specializzata in Trucco
            permanente.
            <br /> Il mio obiettivo è aiutare le persone a far emergere la parte
            migliore di sè, favorendone autostima, sicurezza e fiducia. <br />
            Ho affinato la mia tecnica grazie alla frequentazione di vari master
            presso Swatoslav, Oksana Martinenko, conosciuti come i migliori
            professionisti del settore della micropigmentazione.
          </span>
        </div>
        <div className="d-flex flex-row align-items-center col-12 justify-content-center mt-5">
          <Button
            variant="contained"
            color="primary"
            onClick={handleButtonClick}
            startIcon={<GoogleIcon />}
            style={{
              backgroundColor: "#fff",
              color: "#000",
              fontWeight: "bold",
              padding: "10px 20px",
              borderRadius: "8px",
              textTransform: "none",
              fontSize: "16px",
            }}
          >
            Scrivi una recensione!
          </Button>
        </div>
        <div className="d-flex flex-row align-items-center  justify-content-around col-12  mt-7 ">
          <span>
            <FontAwesomeIcon
              icon={faInstagram as IconProp}
              style={{ fontSize: "3.5rem" }}
              onClick={() => handleInstagram()}
            />
          </span>
          <span>
            <FontAwesomeIcon
              icon={faFacebook as IconProp}
              style={{ fontSize: "3.5rem" }}
              onClick={() => handleFacebook()}
            />
          </span>
          <span>
            <FontAwesomeIcon
              icon={faTiktok as IconProp}
              style={{ fontSize: "3.5rem" }}
              onClick={() => handleTikTok()}
            />
          </span>
        </div>

        <div className="d-flex flex-row align-items-center justify-content-center col-12 mt-10 mb-10 ">
          <div className=" d-flex   flex-column justify-content-start  col-6 ">
            <div className={`  col-12  mt-3  ${styles.singleImage}`}>
              <img src={laura1} />
            </div>
            <div className={`col-12   mt-3  ${styles.singleImage}`}>
              <img src={laura3} />
            </div>
            <div className={`col-12   mt-3  ${styles.singleImage}`}></div>
            {/* <div className={`  col-12 p-1  ${styles.singleImage}`}>
              <img src={logo} />
            </div> */}
          </div>
          <div className=" d-flex  ms-3 flex-column justify-content-start  col-6 ">
            <div className={`  col-12  mt-3  ${styles.singleImage}`}>
              <img src={laura4} />
            </div>
            <div className={`  col-12  mt-3 ${styles.singleImage}`}>
              <img src={laura2} />
            </div>
            <div className={`col-12     ${styles.singleImage}`}></div>
            {/* <div className={`col-12   p-1  ${styles.singleImage}`}>
              <img src={logo} />
            </div> */}
            {/* <div className={`  col-12 p-1  ${styles.singleImage}`}>
              <img src={website} />
            </div> */}
          </div>
        </div>
      </div>
      {/* <Footer
        color={"black"}
        textColor={"white"}
        name={"DLC Tech S.R.L."}
        address={"via Montecchia 22/A"}
        city={"Selvazzano Dentro"}
        country={"Padova (PD)"}
        phone={"+39 0498234396"}
      /> */}
      {/* {menu && (
        <div style={{}}>
          <Menu
            color={"black"}
            textColor={"white"}
            close={() => setMenu(!menu)}
          />
        </div>
      )} */}
    </>
  );
};
export default Laura;
