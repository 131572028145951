import { useEffect, useState } from "react";

import website from "../assets/website.png";
import Ponch from "../assets/MarcoPonch.jpeg";
import Baldan from "../assets/MarcoBaldan.jpeg";
// import Canali from "";

import styles from "./Home.module.scss";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const Home: React.FC = () => {
  const redirect = useNavigate();
  const [menu, setMenu] = useState(false);
  useEffect(() => {
    console.log(window.innerHeight);
  }, [window.innerHeight]);

  return (
    <>
      {/* <Header menu={() => setMenu(!menu)} /> */}
      <div className="d-flex  flex-row align-items-center justify-content-center col-12 col-xl-5    p-5">
        <div
          className={`col-12   ${styles.imageContainer} border d-flex flex-row align-items-start justify-content-center`}
        >
          Immagine/ immagini che girano studio
          {/* <img src={website} /> */}
        </div>
      </div>
      <div className="d-flex flex-column col-12 col-xl-5 p-5 align-items-start justify-content-center">
        <div
          className={`${styles.artistBtn} col-12 border d-flex flex-row align-items-center justify-content-center`}
        >
          <div className={`${styles.imgContainer} col-11 h-100 border`}></div>
          <div
            className={`${styles.name} col-1  d-flex flex-column align-items-center justify-content-start text-s fw-bold`}
          >
            <span>G </span>
            <span> I</span>
            <span> A</span>
            <span> N</span>
            <span> N</span>
            <span> I</span>
          </div>
        </div>

        <div
          className={`${styles.artistBtn} col-12 border mt-5 d-flex flex-row align-items-center justify-content-center`}
        >
          <div
            className={`${styles.name} col-1  d-flex flex-column align-items-center justify-content-start text-s fw-bold`}
          >
            <span>L </span>
            <span> A</span>
            <span> U</span>
            <span> R</span>
            <span> A</span>
          </div>
          <div className={`${styles.imgContainer} col-11 h-100 border`}></div>
        </div>
      </div>

      {/* <Footer color={"black"} textColor={"white"} name={""} address={""} city={""} country={""} phone={""} /> */}
    </>
  );
};
export default Home;
