import { useEffect, useState } from "react";

import logo from "../../assets/factoryTattoo2.png";

import styles from "./Header.module.scss";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useStoreState } from "pullstate";
import { getCurrentMenu } from "../../store/Selector";
import GianniTattooStore from "../../store/GianniTattooStore";

import logoGianni from "../../assets/logo-gianni.png";
import logoLaura from "../../assets/logo-laura-trucco-perfetto-permanente-padova-o.png";

interface HeaderProps {
  menu: () => void;
  open: boolean;
}

const Header: React.FC<HeaderProps> = (props) => {
  const redirect = useNavigate();
  const currentMenu = useStoreState(GianniTattooStore, getCurrentMenu);
  const [menu, setMenu] = useState("");

  useEffect(() => {
    setMenu(currentMenu);
  }, [currentMenu]);

  return (
    <>
      <div
        className={`d-flex flex-row align-items-center justify-content-between p-5 col-12 col-xl-5 ${
          menu === "gianni"
            ? "color-white bg-black"
            : "color-black bg-white"
        } ${styles.container}`}
      >
        <div
          className={` col-6 d-flex flex-row align-items-center justify-content-center ${styles.logoContainer}`}
        >
          <img
            src={menu === "gianni" ? logoGianni : logoLaura}
            onClick={() => redirect("/")}
          />
        </div>
        <div className={`col-2  text-center`}>
          {props.open && (
            <FontAwesomeIcon
              icon={faXmark as IconProp}
              onClick={() => {
                props.menu();
              }}
              className="cursor-pointer"
              style={{ fontSize: "3rem" }}
            />
          )}
          {!props.open && (
            <FontAwesomeIcon
              icon={faBars as IconProp}
              onClick={() => {
                props.menu();
              }}
              className="cursor-pointer"
              style={{ fontSize: "3rem" }}
            />
          )}
        </div>
        {/* <div className={`${styles.center}  col-2`} >
          <div></div>
        </div> */}
      </div>
    </>
  );
};
export default Header;
