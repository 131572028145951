import React, { useEffect, useState } from "react";
import logo from "./logo.svg";

import { BrowserRouter } from "react-router-dom";
import Container from "./Container";
import Header from "./components/Header/Header";
import Menu from "./components/Menu/Menu";
import Footer from "./components/Footer/Footer";
function App() {
  const [menu, setMenu] = useState(false);

  return (
    <>
      <BrowserRouter>
        <div
          className="d-flex flex-column align-items-center justify-content-start"
          style={{ height: "100px !important" }}
        >
          <Header menu={() => setMenu(!menu)} open={menu} />

          {menu && (
            <Menu
              color={"black"}
              textColor={"white"}
              close={() => setMenu(!menu)}
            />
          )}

          <Container />

          <Footer
            color={"black"}
            textColor={"white"}
            name={"PAIN POINT TATOO DI ZANIN GIOVANNI"}
            address={"Piazzale Castagnara, 1"}
            city={"Cadoneghe"}
            country={"Padova (PD)"}
            phone={"+39 3387734525"}
            piva={"P.IVA – 03628100285"}
          />
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
