import { Store } from "pullstate";

export interface IGianniTattooStore {
  currentMenu: string;
}

const GianniTattooStore = new Store<IGianniTattooStore>({
  currentMenu: "",
});

export const setCurrentMenu = (menu: string) => {
  GianniTattooStore.update((s) => {
    s.currentMenu = menu;
  });
};

export default GianniTattooStore;
