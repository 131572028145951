import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import website from "../assets/website.png";
import logo from "../assets/LOGO_FINALE1.png";

import styles from "./Menu.module.scss";
import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import { setCurrentMenu } from "../../store/GianniTattooStore";

import logoGianni from "../../assets/logo-gianni.png";
import logoLaura from "../../assets/logo-laura-trucco-perfetto-permanente-padova-o.png";

interface MenuProps {
  color: string;
  textColor: string;
  close: () => void;
}

const Menu: React.FC<MenuProps> = (props) => {
  const redirect = useNavigate();

  useEffect(() => {
    console.log(window.innerHeight);
  }, [window.innerHeight]);

  return (
    <>
      <div
        className={`${styles.menuContainer} d-flex flex-column align-items-center justify-content-start `}
        style={{ color: `${props.textColor}` }}
      >
        <Header menu={() => props.close()} open={true} />

        <div
          className={`d-flex flex-column col-11 col-xl-4  justify-content-center align-items-center ${styles.menuPage}`}
        >
          <div className={`${styles.GianniHalf}`}>
            <span
              className={`${styles.imageContainerGianni}`}
              onClick={() => {
                setCurrentMenu("gianni");
                redirect("/Gianni");
                props.close();
              }}
            >
              <img src={logoGianni} alt="logo_Gianni" />
            </span>
          </div>
          <div className={`${styles.LauraHalf}`}>
            <span
              className={`${styles.imageContainerLaura} `}
              onClick={() => {
                setCurrentMenu("laura");
                redirect("/Laura");
                props.close();
              }}
            >
              <img src={logoLaura} alt="logo_Laura" />
              {/* Laura */}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default Menu;
