import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import gianni1 from "../assets/IMG_7187.jpg.webp";
import gianni2 from "../assets/IMG_7101.jpg.webp";
import gianni3 from "../assets/IMG_5511.jpg.webp";
import gianni4 from "../assets/IMG_CA5820BECAD9-1.jpeg.webp";
import gianniImg from "../assets/img_chisiamo_.jpg.webp";

import styles from "./Gianni.module.scss";
import { useNavigate, useParams } from "react-router-dom";

import {
  faFacebook,
  faInstagram,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { setCurrentMenu } from "../store/GianniTattooStore";

import { Button } from "@mui/material";
import { Google as GoogleIcon } from "@mui/icons-material";

const Gianni: React.FC = () => {
  const redirect = useNavigate();
  const params = useParams();
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setCurrentMenu("gianni");
  }, []);

  const handleButtonClick = () => {
    window.open(
      "https://search.google.com/local/writereview?placeid=ChIJncw-WozQfkcRwNX092nUhjA",
      "_blank"
    );
  };

  const handleInstagram = () => {
    window.open("https://www.instagram.com/gianni.tattoo", "_blank");
  };

  const handleFacebook = () => {
    window.open("https://www.facebook.com/GianniTattoo", "_blank");
  };

  const handleTikTok = () => {
    window.open("https://www.tiktok.com/@gianni_tattoo", "_blank");
  };

  return (
    <>
      {/* <Header menu={() => setMenu(!menu)} /> */}

      <div className="d-flex flex-column align-items-center justify-content-center col-12 col-xl-5     p-5">
        {/* <div className="d-flex flex-row align-items-center justify-content-center col-12">
          <span
            className="color-black"
            style={{ fontSize: "3.8rem", fontWeight: "bold" }}
          >
            Gianni
          </span>
        </div> */}
        <div
          className={`d-flex flex-row align-items-center justify-content-center col-12 ${styles.ProfileImgContainer} mt-5`}
        >
          <img src={gianniImg} />
        </div>

        <div className="d-flex flex-row align-items-center justify-content-center mt-7 col-xl-10 text-xxl">
          <span
            className="color-black text-center"
            // style={{ fontSize: "2rem" }}
          >
            <i>
              Non ricordo un tempo in cui non sia stato affascinato dall’arte
              del tatuaggio. <br /> I tatuaggi sono per me simbolo di libertà di
              espressione personale. <br /> Ogni tatuaggio comunica qualcosa di
              se stessi.
            </i>
          </span>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-center mt-5 col-xl-9 ">
          <span
            className="color-black text-center text-xxl "
            // style={{ fontSize: "2rem" }}
          >
            Opero nel settore da più di trent’anni.
            <br /> La mia priorità è l'attenzione verso il cliente, con il quale
            cerco di instaurare un rapporto di fiducia e collaborazione
            reciproca. <br /> Cerco di realizzare ogni tatuaggio secondo quelli
            che sono i suoi gusti e desideri, proponendo pensieri ed idee per
            creare un progetto che esprima in pieno la sua persona.
          </span>
        </div>
        <div className="d-flex flex-row align-items-center col-12 justify-content-center mt-5">
          <Button
            variant="contained"
            color="primary"
            onClick={handleButtonClick}
            startIcon={<GoogleIcon />}
            style={{
              backgroundColor: "#fff",
              color: "#000",
              fontWeight: "bold",
              padding: "10px 20px",
              borderRadius: "8px",
              textTransform: "none",
              fontSize: "16px",
            }}
          >
            Scrivi una recensione!
          </Button>
        </div>
        <div className="d-flex flex-row align-items-center  justify-content-around col-12  mt-7 ">
          <span>
            <FontAwesomeIcon
              icon={faInstagram as IconProp}
              style={{ fontSize: "3.5rem" }}
              onClick={() => handleInstagram()}
            />
          </span>
          <span>
            <FontAwesomeIcon
              icon={faFacebook as IconProp}
              style={{ fontSize: "3.5rem" }}
              onClick={() => handleFacebook()}
            />
          </span>
          <span>
            <FontAwesomeIcon
              icon={faTiktok as IconProp}
              style={{ fontSize: "3.5rem" }}
              onClick={() => handleTikTok()}
            />
          </span>
        </div>

        <div className="d-flex flex-row align-items-center justify-content-center col-12 mt-10 mb-10 ">
          <div className=" d-flex   flex-column justify-content-start  col-6 ">
            <div className={`  col-12  mt-3 ${styles.singleImage}`}>
              <img src={gianni1} />
            </div>
            <div className={`col-12   mt-3  ${styles.singleImage}`}>
              <img src={gianni2} />
            </div>
            <div className={`col-12     ${styles.singleImage}`}></div>
            {/* <div className={`  col-12 p-1  ${styles.singleImage}`}>
              <img src={logo} />
            </div> */}
          </div>
          <div className=" d-flex  mt-3 ms-3 flex-column justify-content-start  col-6 ">
            <div
              className={`  col-12    ${styles.singleImage} d-flex flex-column align-items-center border`}
            >
              <img src={gianni3} />
            </div>
            <div
              className={`  col-12  mt-3   ${styles.singleImage} d-flex flex-column align-items-center border`}
            >
              <img src={gianni4} />
            </div>
            <div className={`col-12   p-1  ${styles.singleImage}`}></div>
            {/* <div className={`col-12   p-1  ${styles.singleImage}`}>
              <img src={logo} />
            </div> */}
            {/* <div className={`  col-12 p-1  ${styles.singleImage}`}>
              <img src={website} />
            </div> */}
          </div>
        </div>
      </div>
      {/* <Footer
        color={"black"}
        textColor={"white"}
        name={"DLC Tech S.R.L."}
        address={"via Montecchia 22/A"}
        city={"Selvazzano Dentro"}
        country={"Padova (PD)"}
        phone={"+39 0498234396"}
      /> */}
      {/* {menu && (
        <div style={{}}>
          <Menu
            color={"black"}
            textColor={"white"}
            close={() => setMenu(!menu)}
          />
        </div>
      )} */}
    </>
  );
};
export default Gianni;
