import { Route, Routes } from "react-router-dom";
import Home from "./routes/Home";

import Gianni from "./routes/Gianni";
import Laura from "./routes/Laura";

const Container = () => {
  return (
    <Routes>
      <Route path={"/"} element={<Home />} />
      <Route path={"/Gianni"} element={<Gianni />} />
      <Route path={"/Laura"} element={<Laura />} />
      {/* <Route path={"/prezzofisso"} element={<PrezzoFisso />} /> */}
      {/* <Route path={"/allacarta"} element={<AllaCarta />} /> */}
      {/* <Route path={"/delgiorno"} element={<DelGiorno />} /> */}
    </Routes>
  );
};

export default Container;
